<template>
  <v-container
    class="d-flex justify-center pos-abs overflow-hidden"
    fill-height
    fluid
  >
    <v-progress-circular
      v-if="!$route.query.v"
      indeterminate
      color="primary"
      size="70"
      class="loading-indicator"
    ></v-progress-circular>

    <div v-else class="ant-glass-background login-card" style="z-index: 1">
      <div class="ant-card-header">ANT</div>
      <transition name="fade">
        <div v-if="!twoFactor.display">
          <v-form ref="loginForm" @submit.prevent="authenticate">
            <ant-input label="Email">
              <template #input-field>
                <v-text-field
                  id="email"
                  v-model="authData.email"
                  :rules="[rules.required]"
                  autofocus
                  dense
                  filled
                  placeholder="Email"
                  single-line
                  type="email"
                />
              </template>
            </ant-input>

            <ant-input label="Password" style="margin-top: 0 !important">
              <template #input-field>
                <v-text-field
                  id="password"
                  v-model="authData.password"
                  :append-icon="
                    passwordFieldType === 'password'
                      ? 'mdi-eye-outline'
                      : 'mdi-eye-off-outline'
                  "
                  :rules="[rules.required]"
                  :type="passwordFieldType"
                  autofocus
                  dense
                  filled
                  placeholder="Password"
                  single-line
                  @keydown.enter="authenticate"
                  @click:append="
                    passwordFieldType === 'password'
                      ? (passwordFieldType = 'text')
                      : (passwordFieldType = 'password')
                  "
                />
              </template>
            </ant-input>
          </v-form>
          <div class="d-flex">
            <v-btn
              :loading="loading"
              class="flex-grow-1"
              color="primary"
              elevation="0"
              @click="authenticate"
            >
              LOGIN
            </v-btn>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  href="https://docs.antcde.io/"
                  icon
                  target="_blank"
                  v-on="on"
                >
                  <v-icon class="ant-icon"> mdi-help-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>ANT Documentation</span>
            </v-tooltip>
          </div>
        </div>
        <div v-else>
          <v-form ref="2faForm" @submit.prevent="verifyTwoFactor">
            <ant-input label="2FA code">
              <template #input-field>
                <v-text-field
                  v-model="twoFactor.code"
                  autofocus
                  background-color="transparent"
                  dense
                  filled
                  hide-details
                  placeholder="Authentication code"
                  single-line
                  type="number"
                />
              </template>
            </ant-input>
          </v-form>
          <div class="d-flex">
            <v-btn
              :disabled="twoFactor.code.length !== 6"
              class="flex-grow-1 mt-2"
              color="primary"
              @click="verifyTwoFactor"
            >
              Authenticate
            </v-btn>
          </div>
        </div>
      </transition>
      <div
        v-if="!twoFactor.display"
        class="d-flex justify-center align-center mt-5"
      >
        <router-link
          :to="{ name: 'forgot-password' }"
          class="password-forgotten"
        >
          Forgot password
        </router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import { fetchAzureLoginUrl, verifyTwoFactor } from '@/services/api/user.api';
import { mapGetters } from 'vuex';
import LocalStorageService from '@/services/local-storage';
import AntInput from '@/components/AntInput.vue';

export default {
  components: { AntInput },
  data: () => ({
    authData: {
      email: '',
      password: '',
    },
    loading: false,
    rules: {
      required: (value) => !!value || 'Required.',
    },
    snackbar: false,
    twoFactor: {
      display: false,
      code: '',
    },
    passwordFieldType: 'password',
  }),
  computed: {
    ...mapGetters(['authenticatedUser']),
  },
  mounted() {
    if (!this.$route.query.v) {
      const queryParams = { ...this.$route.query, v: new Date().getTime() };
      const newQueryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');
      window.location.href = `${window.location.pathname}?${newQueryString}`;
      return;
    }

    if (this.$route.query.token) {
      this.$store.commit('auth_success', this.$route.query.token);
      LocalStorageService.setToken(this.$route.query.token);
      this.fetchAuthUser();
    }
    if (
      this.$route.query.username ||
      LocalStorageService.getValue('auth_username')
    ) {
      this.authData.email =
        this.$route.query.username ??
        LocalStorageService.getValue('auth_username');
      LocalStorageService.setValue('auth_username', this.authData.email);
    }
    if (
      this.$route.query.password ||
      LocalStorageService.getValue('auth_password')
    ) {
      this.authData.password =
        this.$route.query.password ??
        LocalStorageService.getValue('auth_password');
      LocalStorageService.setValue('auth_password', this.authData.password);
    }

    if (this.authData.email && this.authData.password) {
      this.login();
    }
  },
  methods: {
    authenticate() {
      if (this.$refs.loginForm.validate()) {
        this.login();
      }
    },
    login() {
      this.loading = true;

      this.$store
        .dispatch('authenticate', {
          username: this.authData.email,
          password: this.authData.password,
        })
        .then(() => {
          this.fetchAuthUser();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchAuthUser() {
      this.$store
        .dispatch('fetchAuthenticatedUser')
        .then((user) => {
          if (user.licenses.length > 0) {
            this.loading = false;
            if (user.language) {
              this.$i18n.locale = user.language;
            } else {
              this.$i18n.locale = 'en';
            }
            if (this.$route.query.license) {
              let license = user.licenses.find(
                (item) => item.id === this.$route.query.license
              );
              if (license) {
                this.$store.commit('set_license', license);
                this.$router.push(this.$route.query.redirect || '/dashboard');
              }
            } else {
              if (user.licenses.length === 1) {
                this.$store.commit('set_license', user.licenses[0]);
                if (user.two_factor_enabled) {
                  this.twoFactor.display = true;
                } else {
                  this.$router.push(this.$route.query.redirect || '/dashboard');
                }
              } else {
                // select license
                if (user.two_factor_enabled) {
                  this.twoFactor.display = true;
                } else {
                  this.$router.push({
                    name: 'Licenses',
                    query: { redirect: this.$route.query.redirect },
                  });
                }
              }
            }
          } else {
            this.$store.commit('showNotification', {
              content: 'You have no active licenses',
              color: 'error',
            });
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async getAzureLoginUrl() {
      const url = await fetchAzureLoginUrl();
      window.open(url, '_self');
    },
    verifyTwoFactor() {
      if (this.$refs['2faForm'].validate()) {
        verifyTwoFactor(this.twoFactor.code)
          .then((response) => {
            if (response.status === 'success') {
              if (this.authenticatedUser.licenses.length === 1) {
                this.$router.push(this.$route.query.redirect || '/dashboard');
              } else {
                this.$router.push('/licenses');
              }
            }
          })
          .catch((error) => {
            this.$store.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-card {
  padding: 25px 100px;

  .ant-card-header {
    text-align: center;
    font-size: 70px;
    color: var(--v-primary-base);
  }
}

.v-icon.icon-questionmark {
  font-size: 34px;
  color: var(--v-primary-base);
}

.password-forgotten {
  text-decoration: none;
  color: var(--v-secondary-lighten2);
  transition: 200ms;

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>
